import React, { useContext } from 'react';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';

// import page style
import './faqs.scss';
// import FaqsDataPatient from 'shared/FaqsDataPatient';

const faqsPage = () => {
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  const metaData = appConfigs?.metaData?.hcp;

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-faqs">
        <ContentBlock>
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <ColorBlock blue>
                  <h2>Frequently asked questions about HCP</h2>
                  <h1 className="orange">
                    NUTRITION DIRECT
                    <span className="brand-trade-mark">&trade;</span> WITH
                    BARIACTIV<sup>&reg;</sup> SUPPLEMENTS
                  </h1>
                  <p>
                    It is not unusual to have questions. Here are some of the
                    most common ones and their answers.
                  </p>
                </ColorBlock>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="hcp-b12options">
      <Seo {...metaData} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default faqsPage;
